<div id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h4>Bądźmy w kontakcie : )</h4>
            <h2>Napisz do <span>nas</span></h2>
            <p>Jeśli masz jakiekolwiek pytania lub wątpliwości, chętnie odpowiemy na nie i pomożemy Ci wybrać najlepsze rozwiązanie. Napisz do nas lub skontaktuj się z nami, a my odpowiemy tak szybko, jak to możliwe. Jesteśmy do Twojej dyspozycji i chętnie pomożemy Ci w realizacji Twoich projektów.</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)" *ngIf="!messageSent">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label for="name">Imię i nazwisko</label>
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Imię i nazwisko jest wymagane</div>
                                        <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                        <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label for="email">Email</label>
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email">
                                    <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email jest wymagany.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label for="subject">Temat</label>
                                    <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control" id="subject">
                                    <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Temat jest wymagany.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label for="number">Numer telefonu</label>
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Numer telefonu jest wymagany.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label for="message">Wiadomość</label>
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="4" class="form-control"></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Wiadomość jest wymagana.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary" [class.disabled]="!contactForm.valid">Wyślij wiadomość</button>
                            </div>
                        </div>
                    </form>
                    <div *ngIf="messageSent">
                        <p>Dziękujemy za kontakt. Wrócimy z odpowiedzią w ciągu jednego dnia.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">                
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li><i class="fas fa-map-marker-alt"></i> <span>Adres:</span><div>Ostrów Wielkopolski</div><div>ul. Kaliska 83/4a</div><div>Polska</div></li>
                                <li><i class="far fa-envelope"></i> <a href="mailto:bartlomiej.filipiak@execode.pl"><span>Email:</span>bartlomiej.filipiak@execode.pl</a></li>
                                <li><i class="fas fa-phone-alt"></i> <a href="tel:609165385"><span>Telefon:</span>(+48) 609 165 385</a></li>
                                <!-- <li><i class="fas fa-fax"></i> <a href="tel:61724160055"><span>Fax:</span>+617-241-60055</a></li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>