<div class="skill-area ptb-100 bg-fffdfd">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="skill-content">
                    <div class="section-title">
                        <h2>Znane nam <span>Technologie</span></h2>
                        <p>Korzystamy z topowych rozwiązań technologicznych</p>
                    </div>
                    <div class="skills-list">
                        <div class="partner-slides">
                            <owl-carousel-o [options]="skillSlides">
                                <ng-template carouselSlide>
                                    <div class="partner-item">
                                        <img src="assets/img/skill-img1.png" alt="skill">
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <div class="partner-item">
                                        <img src="assets/img/skill-img2.png" alt="skill">
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <div class="partner-item">
                                        <img src="assets/img/skill-img3.png" alt="skill">
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <div class="partner-item">
                                        <img src="assets/img/skill-img4.png" alt="skill">
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <div class="partner-item">
                                        <img src="assets/img/skill-img5.png" alt="skill">
                                    </div>
                                </ng-template>
                                <ng-template carouselSlide>
                                    <div class="partner-item">
                                        <img src="assets/img/skill-img6.png" alt="skill">
                                    </div>
                                </ng-template>
                            </owl-carousel-o>
                        </div>
                        <!-- <div class="skill-item">
                            <div class="skill-header">
                                <h4 class="skill-title">Angular</h4>
                                <h4 class="skill-title">REST API</h4>
                                <h4 class="skill-title">Docker</h4>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="skill-video">
                    <img src="assets/img/skill.jpg" alt="video-img">
                    <!-- <div class="video-btn">
                        <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupTwo').open()"><i class="fas fa-play"></i></button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <ngx-smart-modal #popupTwo [identifier]="'popupTwo'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal>
</div>