<footer class="footer-area">
    <div class="container">
        <h3><a routerLink="/"><span>.exe</span>code</a></h3>
        <ul>
            <li><a href="#" target="_blank" class="fab fa-facebook-f" aria-label="Odwiedź nas na facebooku"></a></li>
            <li><a href="https://www.linkedin.com/company/86787950" target="_blank" class="fab fa-linkedin-in" aria-label="Odwiedź nas na linkedinie"></a></li>
            <li><a href="#" target="_blank" class="fab fa-instagram" aria-label="Odwiedź nas na instagramie"></a></li>
        </ul>
        <p>NIP: 622 284 37 59 REGON: 522 933 390</p>
        <p>EXECODE Bartłomiej Filipiak</p>
        <p>© .execode</p>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>