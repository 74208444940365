<div id="automation" class="automation-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h4>Niezawodne rozwiązania</h4>
            <h2>Automatyka <span>Przemysłowa</span></h2>
            <p>Posiadamy niespełna 10 lat doświadczenia w wytwarzaniu oprogramowania dla maszyn oraz linii produkcyjnych. Profil naszej działalności doskonale wpisuje się w obecny trend Industry 4.0. Realizowaliśmy projekty głównie dla wymagającej oraz wysoce ustandaryzowanej branży automotive. Odbiorcami naszych prac byli wiodący światowi producenci samochodów jak i części samochodowych tacy jak Tesla, Mahle czy ZF. Jesteśmy gotowi do realizacji największych wyzwań w tym obszarze.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="automation-content">
                    <div class="section-title">
                        <h2>Robimy <span>Maszyny & Linie produkcyjne</span></h2>
                        <p>Kompleksowo realizujemy tematy związane z maszynami przemysłowymi. </p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Oprogramowanie maszyn</li>
                        <li><i class="fas fa-check"></i>Systemy zarządzania produkcją (MES)</li>
                        <li><i class="fas fa-check"></i>Modernizacje maszyn</li>
                        <li><i class="fas fa-check"></i>Inspekcje wizyjne</li>
                        <li><i class="fas fa-check"></i>Kontrola dostępu do maszyn</li>
                        <li><i class="fas fa-check"></i>Relokacje maszyn</li>
                        <li><i class="fas fa-check"></i>Programowanie robotów</li>
                    </ul><br><br>
                    <p>Oprogramowanie maszyn spod naszej marki cieszy się pozytywnymi recenzjami za sprawą standardu opracowywanego i dokumentowanego latami. Pozwala nam on oszczędzać godzin pracy oraz daje pozytywne wrażenia użytkowania dla operatorów.</p> <br>
                    <p>Wykorzystujemy sprzęt wiodących producentów takich jak Siemens, Allen Bradley, Rexroth, Pilz, Cognex czy Keyence.</p> 
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="automation-video">
                    <img src="assets/img/automation.jpg" alt="automation">
                    <!-- <div class="video-btn">
                        <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="video-popup">
    <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal>
</div> -->