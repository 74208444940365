<div class="partner-area ptb-70 bg-fffdfd">
    <div class="container">
        <div class="section-title">
            <h4>Skuteczna kooperacja</h4>
            <h2>Nasi <span>Partnerzy</span></h2>
            <p>Nasi partnerzy biznesowi to firmy i osoby, z którymi współpracujemy i które cenimy za ich profesjonalizm, wszechstronność i jakość usług. Współpraca z nimi pozwala nam na rozwój i poszerzanie horyzontów, a także na realizację coraz bardziej wymagających projektów. Jesteśmy dumni, że mogliśmy nawiązać współpracę z takimi partnerami i chętnie polecamy ich usługi naszym klientom. Wierzymy, że wspólnymi siłami możemy osiągnąć więcej i zapewnić naszym klientom najlepsze rozwiązania.</p>
        </div>
        <div class="partner-slides">
            <owl-carousel-o [options]="partnerSlides">
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="http://armsolutions.pl/" target="_blank"><img src="assets/img/partner-3.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="#" target="_blank"><img src="assets/img/partner-2.png" alt="partner"></a>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>