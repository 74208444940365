<div id="work" class="work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nasze <span>Realizacje</span></h2>
            <p>Zapoznaj się z naszymi realizacjami i przekonaj się, jakie możliwości daje współpraca z nami. 
                W poniższej galerii znajdziesz przykłady wcześniej wykonanych przez nas projektów, dzięki którym będziesz mógł zobaczyć nasze możliwości i jakość naszej pracy.</p>
        </div>
    </div>
    <div class="container-fluid">
        <div class="work-tabs">
            <ngx-tabset>
                <ngx-tab tabTitle="Wszystkie realizacje">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Flowder</h3>
                                    <ul>
                                        <li><a routerLink="/">System do obsługi zakupów</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img2.webp" alt="work-img">
                                <div class="work-content">
                                    <h3>Notifeed</h3>
                                    <ul>
                                        <li><a href="#">Monitoring zdarzeń</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img6.webp" alt="work-img">
                                <div class="work-content">
                                    <h3>Sklep Internetowy</h3>
                                    <ul>
                                        <li><a routerLink="/">Fioza.pl</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img3.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Tester wrzecienników</h3>
                                    <ul>
                                        <li><a routerLink="/">Oprogramowanie PLC + PC</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Automatyka przemysłowa">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img3.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Tester wrzecienników</h3>
                                    <ul>
                                        <li><a routerLink="/">Oprogramowanie PLC + PC</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Systemy dedykowane">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Flowder</h3>
                                    <ul>
                                        <li><a routerLink="/">System do obsługi zakupów</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img2.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Notifeed</h3>
                                    <ul>
                                        <li><a href="#">Monitoring zdarzeń</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="E-commerce">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img6.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Sklep Internetowy</h3>
                                    <ul>
                                        <li><a href="https://fioza.pl/" target="_blank">Fioza.pl</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
            </ngx-tabset>
        </div>
    </div>
</div>