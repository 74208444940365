<div class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Często zadawane <span>Pytania</span></h2>
            <p>Odpowiedzi na pytania, które klienci często nam zadają. Znajdziesz je poniżej.</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <accordion>
                        <accordion-group heading="Jak długo czeka się za wyceną?">
                            <p>Czas oczekiwania na wycenę jest kwestią indywidualną. Zazwyczaj nasze wyceny są gotowe w ciągu 2 tygodni od momentu zapytania. Proces wyceniania przyspiesza nam komplet informacji dostarczony przez klienta oraz sprawny kontakt.</p>
                        </accordion-group>
                        <accordion-group heading="Jak przebiega realizacja projektu?">
                            <p>Prace zaczynają się często już na etapie wyceniania projektu. Wtedy też powstaje pierwsza koncepcja, 
                               która jest udoskonalana w kolejnych etapach. Nasz klient może w każdej chwili sprawdzić postępy poprzez wizytę w naszej 
                               siedzibie bądź poprzez raporty z przebiegu projektu.</p>
                        </accordion-group>
                        <accordion-group heading="Kto to jest ARM Solutions i PG Corp?">
                            <p>ARM Solutions jest firmą zajmującą się konstruowaniem maszyn oraz ich montażem mechanicznym.</p>
                            <br>
                            <p>Natomiast PG Corp to firma oferująca kompleksowe usługi elektryczne w zakresie budowy maszyn poczynając od projektu, idąc przez montaż elektryczny, kończąc na pomiarach.</p>
                            <br>
                            <p>Pracownicy obu firm to nasi zaprzyjaźnieni ludzie, dlatego też współdzielimy z nimi biuro. Wspólnie zrealizowaliśmy już dziesiątki projektów i współpracy tej nie widać końca : )</p>
                        </accordion-group>
                    </accordion>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq-img.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>