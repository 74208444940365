<section class="funfacts-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-0 col-0 col-sm-0"> -->
                <div class="funfact"></div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="far fa-smile"></i>
                    <h3><span [countUp]="9">00</span></h3>
                    <p>Lat doświadczenia</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-shield-alt"></i>
                    <h3><span [countUp]="50">00</span></h3>
                    <p>Ukończonych projektów</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-0 col-0 col-sm-0"> -->
                <div class="funfact"></div>
            </div>
        </div>
    </div>
</section>