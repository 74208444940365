<div id="e-commerce" class="e-commerce-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h4>Solidne oprogramowanie - konkretne zyski</h4>
            <h2>Rozwiązania <span>E-Commerce</span></h2>
            <p>Opracowujemy rozwiązania e-commerce oparte o platformę WooCommerce i technologię headless. WooCommerce to popularne narzędzie do tworzenia sklepów internetowych, które jest prostym w obsłudze i ma bogate możliwości konfiguracji. Headless to architektura, w której serwer odpowiedzialny za logikę aplikacji jest oddzielony od interfejsu użytkownika, co pozwala na swobodne dostosowywanie wyglądu strony do indywidualnych potrzeb i wymagań. Dzięki takiemu połączeniu uzyskujemy elastyczne i skalowalne rozwiązania e-commerce, które pozwalają na szybkie i efektywne prowadzenie sprzedaży w internecie.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-e-commerce">
                    <i class="fas fa-users"></i>
                    <h3>Woocommerce</h3>
                    <p>Najtańszy i najszybszy sposób na własny sklep internetowy zapewniający możliwosć modyfikacji pod własne potrzeby. Tworzymy sklep, konfigurujemy, modyfikujemy oraz aktualizujemy.</p>
                    <span>1</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-e-commerce">
                    <i class="far fa-lightbulb"></i>
                    <h3>Rozwiązania headless</h3>
                    <p>Jest to architektura sklepu dająca najwięcej możliwości. </p>
                    <span>2</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-e-commerce">
                    <i class="fas fa-tag"></i>
                    <h3>Integracja Allegro</h3>
                    <p>Lubimy wnosić coś od siebie. </p>
                    <span>3</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-e-commerce">
                    <i class="fas fa-tag"></i>
                    <h3>Systemy analityczne</h3>
                    <p>Facebook Pixel, Google Analytics 4</p>
                    <span>4</span>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-e-commerce">
                    <i class="far fa-hand-scissors"></i>
                    <h3>We are designer</h3>
                    <p>Fringilla augue at maximus vest vestibulum. Nam pulvinar vitaessst neque et porttitor. Praesent sed nis.</p>
                    <span>4</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-e-commerce">
                    <i class="fab fa-linode"></i>
                    <h3>We are unique</h3>
                    <p>Fringilla augue at maximus vest vestibulum. Nam pulvinar vitaessst neque et porttitor. Praesent sed nis.</p>
                    <span>5</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-e-commerce">
                    <i class="far fa-life-ring"></i>
                    <h3>We are support</h3>
                    <p>Fringilla augue at maximus vest vestibulum. Nam pulvinar vitaessst neque et porttitor. Praesent sed nis.</p>
                    <span>6</span>
                </div>
            </div> -->
        </div>
    </div>
</div>