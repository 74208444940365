<div class="why-we-different ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>Co nas <span>Wyróżnia</span></h2>
            <p>Wiele jest firm świadczących usługi takie jak my. Dlaczego warto więc współpracować z .execode? Nasi klienci to nasi partnerzy, wiemy o tym, że zadowolony klient wróci do nas i nas poleci. Oprócz tego po wspólnym projekcie stwierdzisz, że opisują nas poniższe cechy.</p>
        </div>
        <div class="tabset why-we-different-tabset">
            <!-- Tab 1 -->
            <input type="radio" name="tabset" id="tab1" aria-controls="speed-flexibility" checked>
            <label for="tab1">Szerokie doświadczenia</label>
            <!-- Tab 2 -->
            <input type="radio" name="tabset" id="tab2" aria-controls="profeesional-work">
            <label for="tab2">Elastyczność</label>
            <!-- Tab 3 -->
            <input type="radio" name="tabset" id="tab3" aria-controls="experienced-staff">
            <label for="tab3">Zrozumienie potrzeb</label>
            <!-- Tab 4 -->
            <input type="radio" name="tabset" id="tab4" aria-controls="custom-support">
            <label for="tab4">Pragmatyzm</label>
            <div class="tab-panels">
                <div id="experienced-staff" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img3.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Szerokie doświadczenia</h3>
                                <p>Realizowaliśmy wiele różnorodnych projektów. Zaczynając od maszyn oraz linii produkcyjnych w branży automotive, idąc przez systemy zarządzania produkcją, procesami zakupów czy też sprzedaży części zamiennych, kończąc na kompletnych sklepach internetowych w branży odzieżowej. Nasi pracownicy mieli w przeszłości okazję pracować w zagranicznych projektach czego koronnym przykładem, którym lubimy się chwalić jest realizacja linii produkcyjnej silników elektrycznych w fabryce Tesla w USA.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="speed-flexibility" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img2.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Elastyczność</h3>
                                <p>
                                    Jesteśmy otwarci na indywidualne potrzeby i oczekiwania naszych klientów, dlatego też chętnie dostosowujemy nasze usługi do ich konkretnych wymagań. Dzięki temu możemy zapewnić im rozwiązania dostosowane do ich indywidualnych potrzeb i oczekiwań.
                                    Elastyczność to także nasza zaleta w kontaktach z klientami. Jesteśmy otwarci na ich uwagi i sugestie, dzięki czemu możemy lepiej zrozumieć ich potrzeby i dostosować naszą ofertę do ich oczekiwań.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="profeesional-work" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img1.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Zrozumienie potrzeb</h3>
                                <p>Zrozumienie potrzeb klienta to jeden z kluczowych elementów skutecznej obsługi i zapewnienia satysfakcji odbiorców naszych usług lub produktów. Dlatego też, nasza firma stawia sobie za cel dokładne poznanie oczekiwań naszych klientów i dostosowanie oferty do ich indywidualnych potrzeb.
                                    Starannie słuchamy uwag i sugestii naszych klientów, aby móc lepiej zrozumieć ich potrzeby i dostarczyć im rozwiązania, które spełnią ich oczekiwania. Dzięki temu możemy zapewnić im wysoką jakość obsługi i zadowolenie z naszych usług lub produktów.               
                                    Zapraszamy do skorzystania z naszych usług i do zaufania nam jako partnerowi, który dba o potrzeby swoich klientów. Jesteśmy przekonani, że dzięki naszemu zaangażowaniu i starannemu podejściu do potrzeb klientów, będą oni zadowoleni z współpracy z nami.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="custom-support" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img4.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Pragmatyzm</h3>
                                <p>Nasza firma charakteryzuje się pragmatycznym podejściem do rozwiązywania problemów. Szukamy i implementujemy tylko racjonalne rozwiązania. Stawiamy się w miejscu użytkownika końcowego i opracowujemy system tak by był wygodny w obsłudze i przede wszystkim przydatny - by ułatwiał dotychczasową pracę i wnosił wartość dodaną.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>