<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container">
        <a class="navbar-brand" routerLink="/"><span>.exe</span>code</a>
        <button class="navbar-toggler" (click)="toggleClass()" role="presentation" aria-label="Rozwiń menu">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><span class="nav-link" (click)="onClick('home')">Strona główna</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('welcome')">O nas</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('team')">Zespół</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('services')">Usługi</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('work')">Portfolio</span></li>
                <!-- <li class="nav-item"><span class="nav-link" (click)="onClick('career')">Kariera</span></li> -->
                <!-- <li class="nav-item"><span class="nav-link" (click)="onClick('blog')">Blog</span></li> -->
                <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">Kontakt</span></li>
            </ul>
            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('home')">Strona główna</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('welcome')">O nas</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('team')">Zespół</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('services')">Usługi</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('work')">Portfolio</span></li>
                <!-- <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('career')">Kariera</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('blog')">Blog</span></li> -->
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('contact')">Kontakt</span></li>
            </ul>
        </div>
    </div>
</nav>