<div class="main-banner item-bg-one">
    <!-- Img tag bellow for preloading purpose for performance -->
    <img src="assets/img/main-bg-1.webp" style="display: none">
    <particles [params]="myParams" [style]="myStyle" [width]="width" [height]="height"></particles>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text text-end">
                    <h4>Profesjonalizm to my</h4>
                    <h1>NAJLEPSZE <span>CYFROWE</span> DOŚWIADCZENIA</h1>
                    <ngx-typed-js [strings]="['Tworzymy lepszą przyszłość łącząc technologie']" [typeSpeed]="100" [loop]="false" [backSpeed]="100" [showCursor]="false">
                        <p class="typing"></p>
                    </ngx-typed-js>
                    <button (click)="onClick('welcome')" class="btn btn-primary view-work">Poznaj nas</button>
                    <button (click)="onClick('work')" class="btn btn-primary view-work">Zobacz nasze realizacje</button>
                    <button (click)="onClick('contact')" class="btn btn-primary">Skontaktuj się</button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-welcome></app-welcome>

<app-automation></app-automation>

<app-dedicated-systems></app-dedicated-systems>

<app-e-commerce></app-e-commerce>

<app-cta></app-cta>

<app-skill></app-skill>

<app-funfacts></app-funfacts>

<app-team></app-team>

<!-- <app-how-we-work></app-how-we-work> -->

<app-services></app-services>

<app-why-we-different></app-why-we-different>

<app-work></app-work>

<!-- <app-pricing></app-pricing> -->

<app-faq></app-faq>

<app-feedback></app-feedback>

<!-- <app-blog></app-blog> -->

<app-partner></app-partner>

<app-subscribe></app-subscribe>

<app-contact></app-contact>