<div id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h4>Urzeczywistniamy pomysły</h4>
            <h2>Witamy w <span>.execode</span></h2>
            <p>Jesteśmy firmą programistyczną. Zajmujemy się tworzeniem rozwiązań IT oraz automatyki przemysłowej. Za pomocą oprogramowania oraz automatyzacji usprawniamy procesy w przedsiębiorstwach. Zapraszamy do zapoznania się z naszą ofertą.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fa fa-industry" aria-hidden="true"></i>
                    </div>
                    <h3>Automatyka</h3>
                    <p>Potrzebujesz maszyny produkcyjnej lub stanowiska zrobotyzowanego? Może potrzebna jest tylko modyfikacja? Wraz z naszymi partnerami ARM Solutions (mechanika) oraz PG Corp (elektryka) pomożemy Ci wypracować niezawodne, solidne i optymalne rozwiązanie.</p>
                    <button (click)="onClick('automation')" title="Dowiedz się więcej" class="link-btn"><i class="fa fa-arrow-right"></i></button>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-laptop"></i>
                    </div>
                    <h3>Systemy dedykowane</h3>
                    <p>Masz pomysł by usprawnić pracę swojej organizacji poprzez cyfryzację? System obsługi zakupów wewnętrznych? A może coś do zarządzania produkcją i jej monitorowania? Wspólnie znajdziemy i wykorzystamy potencjał.</p>
                    <button (click)="onClick('dedicated-systems')" title="Dowiedz się więcej" class="link-btn"><i class="fa fa-arrow-right"></i></button>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fa fa-shopping-basket" aria-hidden="true"></i>
                    </div>
                    <h3>E-Commerce</h3>
                    <p>Szukasz specjalistów, którzy E-Commerce znają bardziej niż tylko przez pryzmat kodu? Woocommerce, jak i rozwiązania w architekturze headless? Śmiało uderzaj do nas : )</p>
                    <button (click)="onClick('e-commerce')" title="Dowiedz się więcej" class="link-btn"><i class="fa fa-arrow-right"></i></button>
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</div>