<div class="cta-area ptb-100">
    <div class="container">
        <div class="cta-content">
            <div class="section-title mb-0">
                <h4>Porozmawiajmy o Twoim projekcie</h4>
                <h2>Umów się na bezpłatną konsultację</h2>
                <p>Napisz lub zadzwoń do nas, wspólnie przeanalizujemy problem i wypracujemy najlepsze rozwiązanie</p>
                <button (click)="onClick('contact')" class="btn btn-primary">Skontaktuj się z nami</button>
                <button (click)="onClick('work')" class="btn btn-primary view-work">Zobacz nasze realizacje</button>
            </div>
        </div>
    </div>
</div>