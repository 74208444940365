<div id="dedicated-systems" class="strategy-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-image">
                    <img src="assets/img/dedicated-systems.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <h4>Szyte na miarę</h4>
                        <h2>Systemy <span>Dedykowane</span></h2>
                        <p>Wytwarzamy oprogramowanie komputerowe szyte na miarę. Skupiamy się nad tym by wnieść wartość dodaną dla organizacji naszych klientów. Wnikliwie analizujemy potrzeby oraz aktualne procesy we firmie by zaproponować najoptymalnmiejsze rozwiązania.</p><br>
                        <p>Kilka informacji o naszych systemach:</p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fa fa-check"></i>Technologia webowa - dostęp z poziomu przeglądarki</li>
                        <li><i class="fa fa-check"></i>Rozwiązania poprzedzone badaniem ich przydatności</li>
                        <li><i class="fa fa-check"></i>Tworzenie innowacyjnych, dających korzyść rozwiązań</li>
                    </ul>
                    <p></p>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>
