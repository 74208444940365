import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Meta, MetaDefinition } from "@angular/platform-browser";

@Component({
    selector: 'app-home-thirteen',
    templateUrl: './home-thirteen.component.html',
    styleUrls: ['./home-thirteen.component.scss']
})
export class HomeThirteenComponent implements OnInit {

    constructor(private viewportScroller: ViewportScroller, private metaService: Meta) {
        this.addSeoTags();
    }

    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    myStyle: object = {};
    myParams: object = {};
    width: number = 100;
    height: number = 100;
 
    ngOnInit() {
        this.myStyle = {
            'top': 0,
            'left': 0,
            'width': '100%',
            'height': '100%',
            'position': 'absolute'
        };
        this.myParams = {
            particles: {
                number: {
                    value: 80,
                },
                color: {
                    value: '#ffffff'
                },
                shape: {
                    type: 'stroke',
                },
            }
        };
    }

    private addSeoTags(): void {
        const tags: MetaDefinition[] = [
            { 
                name: 'title', 
                content: 'Aplikacje dla przemysłu - Polepsz swoją produkcję - .execode' 
            },
            { 
                name: 'description', 
                content: 'Dedykowane programy IT dla PC oraz maszyn specjalnych. Systemy Industry 4.0. Analizujemy, automatyzujemy i usprawniamy procesy we firmach.' 
            },
            { 
                name: 'robots', 
                content: 'index,follow' },
            { 
                property: 'og:title', 
                content: 'Firma programistyczna - IT dla przemysłu i e-commerce - .execode' 
            },            
            { 
                property: 'og:description', 
                content: 'Rozwijamy firmy za pomocą oprogramowania oraz automatyzacji. Specjalizujemy się w branży przemysłowej oraz e-commerce.' 
            },
            { 
                property: 'og:type', 
                content: 'website' 
            },
            {
                property: 'og:url',
                content: 'https://execode.pl'
            },
            {
                property: 'og:image',
                content: 'https://execode.pl/assets/img/dedicated-systems.jpg'
            },
            {
                property: 'og:locale',
                content: 'pl_PL'
            },

        ]
        this.metaService.addTags(tags);
    }

}