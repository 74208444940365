import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    constructor(private viewportScroller: ViewportScroller, private httpClient: HttpClient) {}

    public messageSent: boolean = false;

    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
    }

    submit(form){
        form = {
            ...form,
            access_key: '77fb40be-7eb0-48c0-997b-f83446625f6a'
        }
        this.httpClient.post("https://api.web3forms.com/submit", form).subscribe(() => this.messageSent = true)
    }

}