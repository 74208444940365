<div id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nasze <span>Usługi</span></h2>
            <p>Nasze wieloletnie doświadczenie zarówno w branży IT jak i w branży maszynowej pozwala oferować naszym klientom szeroki zakres usług</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img1.webp" alt="services-img">
                    </div>
                    <div class="services-content">
                        <h3>Dedykowane systemy dla przedsiębiorstw</h3>
                        <p>Pomagamy usprawniać procesy we firmach za pomocą oprogramowania. Przedstaw nam problem, wspólnie wypracujemy najlepsze rozwiązanie i wytyczymy ścieżkę rozwoju cyfryzacji w Twojej organizacji.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img2.webp" alt="services-img">
                    </div>
                    <div class="services-content">
                        <h3>Sklepy internetowe</h3>
                        <p>Dla branży e-commerce oferujemy kompletne sklepy internetowe oparte o WooCommerce wraz z integracjami do portali aukcyjnych takich jak Allegro, Olx, Shopee czy też integracjami z systemami kurierskimi.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img3.webp" alt="services-img">
                    </div>
                    <div class="services-content">
                        <h3>Strony internetowe</h3>
                        <p>Oferujemy strony www typu landing page, które pozwolą zaistnieć Ci w sieci jako solidna marka i przyniosą konwersje w postaci pozyskania nowych klientów.</p>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img4.jpg" alt="services-img">
                    </div>
                    <div class="services-content">
                        <h3>Oprogramowanie dla maszyn</h3>
                        <p>Podchodzimmy kompleksowo do zadań związanych z automatyką. Programujemy sterowniki PLC oraz panele operatorskie HMI jak i roboty, wkrętarki oraz inne urządzenia peryferyjne. Przygotowujemy również dokumentację.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img4.jpg" alt="services-img">
                    </div>
                    <div class="services-content">
                        <h3>Modernizacje maszyn</h3>
                        <p>Wzbogacamy istniejące maszyny o nowe funkcjonalności. Wymieniamy też przestarzałe systemy sterowania na nowe, dając nowe życie starym maszynom.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img5.jpg" alt="services-img">
                    </div>
                    <div class="services-content">
                        <h3>Relokacje maszyn</h3>
                        <p>Ponownie uruchamiamy maszyny, które musiały zostać przeniesione w inne miejsce.</p>
                    </div>
                </div>
            </div> -->
            <!--<div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img6.jpg" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-headphones-alt"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Ui & Ux Design</h3>
                        <p>Lorem ipsum dolor sit amet consecte, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img7.jpg" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-mobile-alt"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Responsive Design</h3>
                        <p>Lorem ipsum dolor sit amet consecte, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img8.jpg" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-camera-retro"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Photography</h3>
                        <p>Lorem ipsum dolor sit amet consecte, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img9.jpg" alt="services-img">
                        <div class="icon">
                            <i class="far fa-life-ring"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Custom Support</h3>
                        <p>Lorem ipsum dolor sit amet consecte, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>