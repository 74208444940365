<div class="subscribe-area ptb-100">
    <div class="container">
        <div class="newsletter">
            <h2>Dołącz do newslettera</h2>
            <form class="newsletter-form">
                <input type="email" class="form-control" placeholder="Twój adres e-mail">
                <button class="btn btn-primary" type="submit">Subskrybuj</button>
            </form>
        </div>
    </div>
</div>