<div id="team" class="team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h4>Poznaj .execode</h4>
            <h2>Nasz Zespół <span>Specjalistów</span></h2>
            <p>Firmę stanowią ludzie. Jest to nasz największy kapitał, największa wartość. Nasza kadra składa się z doświadczonych programistów, którzy stale poszerzają swoją wiedzę i umiejętności, by móc zapewnić naszym klientom najlepsze rozwiązania.</p>
        </div>
        <div class="team-slides">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/team-1.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Bartłomiej Filipiak</h3>
                            <span class="post">Programista</span>
                        </div>
                        <div class="social">
                            <ul>
                                <li><a href="https://www.linkedin.com/in/bart%C5%82omiej-filipiak/" target="_blank" class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>