<section class="feedback-area ptb-100">
    <div class="container">
        <div class="feedback-slides">
            <owl-carousel-o [options]="feedbackSlides">
                <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>“Od samego początku postawiliśmy wysoko poprzeczkę jeśli chodzi o wykonanie sklepu internetowego oraz jego support. Oczekiwaliśmy pełnego zaangażowania, szybkiego działania i wszystko to otrzymaliśmy. Wysoka jakość wykonania, dbanie o szczegóły i bardzo sprawny kontakt - wszystkim tym charakteryzuje się firma .execode.”</p>
                        <div class="info">
                            <h3>Kamila Pawlik</h3>
                            <span>Fioza.pl</span>
                            <img src="assets/img/user1.jpg" class="shadow rounded-circle" alt="image">
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>